import React from "react";
import { FaGitAlt, FaUbuntu, FaTeamspeak } from "react-icons/fa";
import { SiGnubash } from "react-icons/si";
import { FaRocketchat } from "react-icons/fa6";
import { GiGemChain } from "react-icons/gi";

const SkillItem = ({ Icon, name, level }) => {
    return (
        <div className="skills__data">
            <Icon />
            <div>
                <h3 className="skills__name">{name}</h3>
                <span className="skills__level">{level}</span>
            </div>
        </div>
    );
};

const Tools = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Gen AI and Other</h3>
            <div className="skills__box">
                <div className="skills__group">
                    <SkillItem Icon={FaRocketchat} name="ChatGPT" level="Advance" />
                    <SkillItem Icon={FaTeamspeak} name="Prompt Engg." level="Intermediate" />
                    <SkillItem Icon={GiGemChain} name="Langchain" level="Basic" />
                </div>
                <div className="skills__group">
                    <SkillItem Icon={FaGitAlt} name="Git" level="Intermediate" />
                    <SkillItem Icon={SiGnubash} name="Bash" level="Basic" />
                    <SkillItem Icon={FaUbuntu} name="Ubuntu" level="Intermediate" />
                </div>
            </div>
        </div>
    );
}

export default Tools;