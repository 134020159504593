import React from "react";
import { FaJava } from "react-icons/fa";
import { SiSpring, SiFastapi, SiPostgresql, SiMongodb } from "react-icons/si";
import { TbBrandPython } from "react-icons/tb";


const SkillItem = ({ Icon, name, level }) => {
    return (
        <div className="skills__data">
            <Icon />
            <div>
                <h3 className="skills__name">{name}</h3>
                <span className="skills__level">{level}</span>
            </div>
        </div>
    );
};

const Backend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Backend Development</h3>
            <div className="skills__box">
                <div className="skills__group">
                    <SkillItem Icon={FaJava} name="Java" level="Advance" />
                    <SkillItem Icon={TbBrandPython} name="Python" level="Advance" />
                    <SkillItem Icon={SiPostgresql} name="PostgreSQL" level="Intermediate" />
                </div>
                <div className="skills__group">
                    <SkillItem Icon={SiSpring} name="SpringBoot" level="Intermediate" />
                    <SkillItem Icon={SiFastapi} name="FastAPI" level="Intermediate" />
                    <SkillItem Icon={SiMongodb} name="MongoDB" level="Basic" />
                </div>
            </div>
        </div>
    );
}

export default Backend;