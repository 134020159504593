import React from 'react';
import { HiOutlineDesktopComputer, HiOutlineTerminal } from "react-icons/hi";
import { BiHappyBeaming } from "react-icons/bi";

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box">
        <HiOutlineDesktopComputer className="about__icon" />
        <h3 className="about__title">Job Exp.</h3>
        <span className="about__subtitle">1+ Years</span>
      </div>
      <div className="about__box">
        <HiOutlineTerminal className="about__icon" />
        <h3 className="about__title">Completed</h3>
        <span className="about__subtitle">30+ Projects</span>
      </div>
      <div className="about__box">
        <BiHappyBeaming className="about__icon_2" />
        <h3 className="about__title">Happy Clients</h3>
        <span className="about__subtitle">20+</span>
      </div>
    </div>
  );
}

export default Info;