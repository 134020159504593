import React from "react";
import { FaHtml5, FaReact, FaCss3Alt } from "react-icons/fa";
import { TbBrandNextjs, TbBrandTailwind, TbBrandJavascript } from "react-icons/tb";

const Frontend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Frontend Development</h3>
            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <FaHtml5 />
                        <div>
                            <h3 className="skills__name">HTML</h3>
                            <span className="skills__level">Advance</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <TbBrandTailwind />
                        <div>
                            <h3 className="skills__name">Tailwind</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <FaReact />
                        <div>
                            <h3 className="skills__name">ReactJS</h3>
                            <span className="skills__level">Advance</span>
                        </div>
                    </div>
                </div>
                <div className="skills__group">
                    <div className="skills__data">
                        <FaCss3Alt />
                        <div>
                            <h3 className="skills__name">CSS</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <TbBrandJavascript />
                        <div>
                            <h3 className="skills__name">Javascript</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                    <div className="skills__data">
                        <TbBrandNextjs />
                        <div>
                            <h3 className="skills__name">NextJS</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Frontend;