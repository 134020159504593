import React, { useState } from 'react';
import './services.css';
import { HiOutlineClipboardList, HiOutlineArrowSmRight, HiOutlineCheckCircle, HiX } from 'react-icons/hi';

const Services = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const services = [["Web page and Mobile app development", "Integrate creative colloboration", "Cross platform mobile app support (Android & IOS)"], ["Create a user friendly interface", "Develop with a minimal approach", "Design with accessibility in mind"], ["Scalable design development", "Secure and fast apis", "Rapid development and deployment"]]

    return (
        <section className="services section" id="services">
            <h2 className="section__title">Services</h2>
            <span className="section__subtitle">Freelance + Create + Collaborate</span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <HiOutlineClipboardList className="services__icon" />
                        <h3 className="services__title">Web + App<br />Developer</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(1)}>
                        View More
                        <HiOutlineArrowSmRight className="services__button-icon" />
                    </span>
                    <div className={toggleState === 1 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <HiX onClick={() => toggleTab(0)} className="services__modal-close" />
                            <h3 className="services__modal-title">Web App & Android App</h3>
                            <p className="services__modal-description">
                                Over 3 years of experience in web development providing quality work.
                            </p>
                            <ul className="services__modal-services grid">
                                {services[0].map((data) => (
                                    <li className="services__modal-service">
                                        <HiOutlineCheckCircle className="services__modal-icon" />
                                        <p className="services__modal-info">
                                            {data}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <HiOutlineClipboardList className="services__icon" />
                        <h3 className="services__title">Desktop App<br />Developer</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(2)}>
                        View More
                        <HiOutlineArrowSmRight className="services__button-icon" />
                    </span>
                    <div className={toggleState === 2 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <HiX onClick={() => toggleTab(0)} className="services__modal-close" />
                            <h3 className="services__modal-title">Desktop App Windows</h3>
                            <p className="services__modal-description">
                                Over 2 years of experience in Desktop app development providing quality work.
                            </p>
                            <ul className="services__modal-services grid">
                                {services[1].map((data) => (
                                    <li className="services__modal-service">
                                        <HiOutlineCheckCircle className="services__modal-icon" />
                                        <p className="services__modal-info">
                                            {data}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <HiOutlineClipboardList className="services__icon" />
                        <h3 className="services__title">Backend<br />Development</h3>
                    </div>
                    <span className="services__button" onClick={() => toggleTab(3)}>
                        View More
                        <HiOutlineArrowSmRight className="services__button-icon" />
                    </span>
                    <div className={toggleState === 3 ? "services__modal active-modal" : "services__modal"}>
                        <div className="services__modal-content">
                            <HiX onClick={() => toggleTab(0)} className="services__modal-close" />
                            <h3 className="services__modal-title">Backend</h3>
                            <p className="services__modal-description">
                                Over 3 years experience in backend providing quality work.
                            </p>
                            <ul className="services__modal-services grid">
                                {services[2].map((data) => (
                                    <li className="services__modal-service">
                                        <HiOutlineCheckCircle className="services__modal-icon" />
                                        <p className="services__modal-info">
                                            {data}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Services;