import React from "react";
import { SiFirebase, SiGooglecloud } from "react-icons/si";
import { VscAzure } from "react-icons/vsc";
import { FaAws, FaDocker } from "react-icons/fa";
import { IoTerminalOutline } from "react-icons/io5";

const SkillItem = ({ Icon, name, level }) => {
    return (
        <div className="skills__data">
            <Icon />
            <div>
                <h3 className="skills__name">{name}</h3>
                <span className="skills__level">{level}</span>
            </div>
        </div>
    );
};

const Cloud = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Cloud Development</h3>
            <div className="skills__box">
                <div className="skills__group">
                    <SkillItem Icon={FaAws} name="AWS" level="Intermediate" />
                    <SkillItem Icon={VscAzure} name="Azure" level="Basic" />
                    <SkillItem Icon={SiGooglecloud} name="GCP" level="Basic" />
                </div>
                <div className="skills__group">
                    <SkillItem Icon={IoTerminalOutline} name="Boto3" level="Intermediate" />
                    <SkillItem Icon={FaDocker} name="Docker" level="Intermediate" />
                    <SkillItem Icon={SiFirebase} name="Firebase" level="Intermediate" />
                </div>
            </div>
        </div>
    );
}

export default Cloud;